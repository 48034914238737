import React, { Component } from 'react'
import styled from 'styled-components'
import GlobalStyles from '../components/globalStyles'
import Header from '../components/header'
import axios from 'axios';
import { URL } from '../utils/utils'
import calendarIcon from '../images/calendar-icon-black.svg'
import pinIcon from '../images/pin-icon-black.svg'
import phoneIcon from '../images/phone-icon-black.svg'
import checkIcon from '../images/check-icon-green.svg'
import homeIcon from '../images/home-icon-black.svg'
import officeIcon from '../images/office-icon-black.svg'
import moment from 'moment'
import { Link } from 'gatsby'
import LabeledCheckbox from '../components/labeledCheckbox'


const ColumnsWrapper = styled.div`
  font-family: ProximaNova-Regular, sans-serif;
  background-color: #009ba8;
  color: #494949;
  width: 100%;
  padding: 30px 0;

  @media (max-width: 600px) {
    padding: 0;
  }
`

const Columns = styled.div`
  margin: 0 auto;
  width: 80%;
  display: grid;
  grid-template-columns: 5fr 3fr;
  gap: 120px;

  @media (max-width: 1200px) {
    display: block;
  }

  @media (min-width: 1600px) {
    width: 70%;
  }

  @media (max-width: 800px) {
    width: 90%;
    padding: 30px 15px;
  }

  @media (max-width: 480px) {
    width: 96%;
  }
`

const LeftColumn = styled.div`
  background-color: #FCFCFC;
  border-radius: 10px;
  padding: 48px;

  @media (max-width: 1200px) {
    margin-bottom: 30px;
  }

  @media (max-width: 800px) {
    padding: 24px;
  }
`

const RightColumn = styled.div`
  background-color: #FCFCFC;
  border-radius: 10px;
  padding: 48px;

  @media (max-width: 800px) {
    padding: 24px;
  }

  .first-line {
    display: flex;

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      margin-right: 20px;
      margin-bottom: 24px;
      border-radius: 4px;
    }

    .name {
      margin-bottom: 4px;
    }

    .category {
      
    }
  }

  .visit-info-line {
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    &:last-of-type {
      margin-bottom: 0;
    }

    img {
      width: 20px;
      margin-right: 10px;
    }
  }
`

const H3 = styled.h3`
  color: #009ba8;
  font-family: 'GaramondCond-Bold', sans-serif;
  font-size: 20px;
  letter-spacing: 0.12px;
  margin: 0 0 26px;
`

const InputSection = styled.section`
  margin-bottom: 20px;

  .phone-fields {
    display: flex;

    input:nth-of-type(1) {
      width: 50px;
      margin-right: 10px;
    }
  }
`

const Label = styled.div`
  margin-bottom: 6px;
`

const ButtonsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 700px) {
    display: block;
  }

  a {
    color: #4499A6;
    cursor: pointer;

    @media (max-width: 700px) {
      display: block;
      margin-bottom: 16px;
    }
  }
`

const Button = styled.div`
  padding: 0 24px;
  height: 40px;
  min-width: 160px;
  background: #4499A6;
  color: #fff;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const ButtonReversed = styled.div`
  padding: 0 24px;
  min-height: 40px;
  background: #fff;
  color: #4499A6;
  border: 1px solid #4499A6; 
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

`

const TextInput = styled.input`
  width: 100%;
  height: 40px;
  background: #fff;
  border: 1px solid ${props => props.error ? "red" : "rgba(0, 0, 0, 0.25)"};
  border-radius: 4px;
  outline: none;
  padding: 0 10px;
`

const TextareaInput = styled.textarea`
  font-family: ProximaNova-Regular, sans-serif;
  width: 100%;
  height: 120px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  outline: none;
  padding: 10px;
`

const RequiredInfo = styled.div`
  margin-bottom: 24px;
  font-size: 14px;
`

const FieldError = styled.div`
  color: red;
  margin-top: 4px;
  font-size: 13px;
`


const SlotUnavailableMessageWrapper = styled.div`
  font-family: ProximaNova-Regular, sans-serif;
  border-radius: 20px;
  background-color: #009ba8;
  color: #fff;
  width: 90vw;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 60px;
  padding: 40px 30px;
  text-align: center;
  font-size: 18px;

  a {
    font-size: 14px;
    display: block;
    color: #fff;
    margin-top: 30px;
  }
`

const SuccessPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #494949;
`

const SuccessInfoBoxWrapper = styled.div`
  flex: 1;
  font-family: ProximaNova-Regular, sans-serif;
  background-color: #009ba8;
  padding: 50px 16px;
`

const SuccessInfoBox = styled.div`
  margin: 0 auto;
  max-width: 580px;
  background: #FCFCFC;
  border-radius: 16px;
  padding: 40px;

  @media (max-width: 600px) {
    padding: 30px 20px;
  }
`

const VisitDataWrapper = styled.div`
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 48px 0;
`

const VisitData = styled.div`
  max-width: 320px;
  margin: 40px auto;
  padding: 0 10px;
`

const InfoLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
  
  img {
    margin-right: 10px;
  }

  &:first-of-type {
    font-size: 18px;
    font-weight: 600;
  }
`

const OnlineInfo = styled.div`

  @media (max-width: 600px) {
    font-size: 14px;
  }

  p:first-of-type {
    margin-bottom: 0;
    color: #EB5757;
  }

  p:nth-of-type(2) {
    margin-top: 4px;
  }

  a {
    color: #2f80ed;
  }
`

const DoctorInfo = styled.div`
  display: flex;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
    margin-bottom: 24px;
    border-radius: 4px;
  }

  .name {
    margin-bottom: 4px;
    font-weight: 600;
  }
`


class BookingPage extends Component {
  state = {
    visitData: {},
    bookingSuccessful: false,
    name: '',
    nameError: false,
    surname: '',
    surnameError: false,
    phone1: '+41',
    phone1Error: false,
    phone2: '',
    phone2Error: false,
    email: '',
    emailError: false,
    additionalInfo: '',
    isPatient: false,
    agreement1: false,
    agreement1Error: false,
    links: [],
  }

  componentDidMount() {
    const visitId = new URLSearchParams(window.location.search).get('id')

    axios.get(`${URL}/api/visits/${visitId}`)
      .then(response => {
        this.setState({ visitData: response.data })
      })
      .catch(err => console.log(err))

    axios.get(`${URL}/api/links`)
      .then(response => {
        this.setState({ links: response.data })
      })
      .catch(err => console.log(err))
  }

  render() {
    const { visitData, links } = this.state

    let privacyPolicyLink = '/'
    if (links.length && links.find(link => link.name === "PRIVACY_POLICY")) privacyPolicyLink = URL + links.find(link => link.name === "PRIVACY_POLICY").url
    let zoomGuideLink = '/'
    if (links.length && links.find(link => link.name === "ZOOM_GUIDE_WEB")) zoomGuideLink = URL + links.find(link => link.name === "ZOOM_GUIDE_WEB").url

    const validateEmail = email => {
      // eslint-disable-next-line
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    }
    
    const validateInputs = () => {
      const nameError = !this.state.name
      const surnameError = !this.state.surname
      const phone1Error = !this.state.phone1 || this.state.phone1.length < 2
      const phone2Error = !this.state.phone2 || this.state.phone2.length < 6
      const emailError = !validateEmail(this.state.email)
      const agreement1Error = !this.state.agreement1

      if (
        !nameError
        && !surnameError
        && !phone1Error
        && !phone2Error
        && !emailError
        && !agreement1Error
      ) {
        bookVisit()
      }

      this.setState({
        nameError: nameError,
        surnameError: surnameError,
        phone1Error: phone1Error,
        phone2Error: phone2Error,
        emailError: emailError,
        agreement1Error: agreement1Error,
      })
    }

    const bookVisit = () => {
      axios.post(`${URL}/api/book-visit`, {
        name: `${this.state.name} ${this.state.surname}`,
        phone: `${this.state.phone1} ${this.state.phone2}`,
        email: this.state.email,
        additional_info: this.state.additionalInfo,
        visit_id: new URLSearchParams(window.location.search).get('id'),
        is_patient: this.state.isPatient,
      })
        .then(response => {
          this.setState({ bookingSuccessful: true })
        })
    }

    if (visitData.is_reserved) {
      return <div>
        <GlobalStyles />
        <Header />
        <SlotUnavailableMessageWrapper>
          <div>Entschuldigung, dieser Slot ist nicht mehr verfügbar.</div>
          <Link to={`/location-details/?place=${visitData.place && visitData.place.id}&category=${visitData.doctor && visitData.doctor.category.id}`}>&lt; Zurück</Link>
        </SlotUnavailableMessageWrapper>
      </div>
    }

    if (this.state.bookingSuccessful) {
      return <SuccessPageWrapper>
        <GlobalStyles />
        <Header />
        <SuccessInfoBoxWrapper>
          <SuccessInfoBox>
            <center>
              <H3>Buchungsbestätigung</H3>
            </center>
            <VisitDataWrapper>
              <VisitData>
                <InfoLine>
                  <img src={checkIcon} alt="booking successful" />
                  Der Besuch wurde bestätigt.
                </InfoLine>
                <DoctorInfo>
                  <img src={URL + (visitData.doctor && visitData.doctor.pathImage)} alt={visitData.doctor && visitData.doctor.name} />
                  <div>
                    <div className="name">{visitData.doctor && visitData.doctor.name}</div>
                    <div className="category">{visitData.doctor && visitData.doctor.category.name}</div>
                  </div>
                </DoctorInfo>
                <InfoLine>
                  <img src={calendarIcon} alt="visit date" />
                  {moment(visitData.start_date).format('D MMMM YYYY, HH:mm')}
                </InfoLine>
                <InfoLine>
                  <img src={pinIcon} alt="clinic location" />
                  {visitData.place && `${visitData.place.city} ${visitData.place.street}`}
                </InfoLine>
                <InfoLine>
                  <img src={phoneIcon} alt="clinic phone" />
                  {visitData.place && visitData.place.phone}
                </InfoLine>
                <InfoLine>
                  <img src={visitData.online ? homeIcon : officeIcon} alt="visit type" />
                  {visitData.online ? 'Online Gespräch' : 'Praxisbesuch'}
                </InfoLine>
                <OnlineInfo>
                  <p>Achtung!</p>
                  <p>Haben Sie einen Termin zur Online Sprechstunde gebucht? Dann haben wir Ihnen den Link zu unserem Virtuellen Sprechzimmer per Mail zugesendet. Eine detaillierte Anleitung finden Sie <a href={zoomGuideLink}>hier</a>. Gerne helfen wir Ihnen auch telefonisch weiter.</p>
                </OnlineInfo>
              </VisitData>
            </VisitDataWrapper>
            <ButtonsWrapper>
              <Link to="/"><ButtonReversed>Buchen sie einen anderen Termin</ButtonReversed></Link>
              <a href="http://www.orthopodo.ch"><Button>Zurück zur Startseite</Button></a>
            </ButtonsWrapper>
          </SuccessInfoBox>
        </SuccessInfoBoxWrapper>
      </SuccessPageWrapper>
    }

    return (
      <div>
        <GlobalStyles />
        <Header />
        <ColumnsWrapper>
          <Columns>
            <LeftColumn>
              <H3>Buchung</H3>
              <InputSection>
                <Label>Vorname*</Label>
                <TextInput 
                  type="text" 
                  value={this.state.name}
                  error={this.state.nameError}
                  onChange={e => { this.setState({ name: e.target.value }) }}  
                />
                { this.state.nameError && <FieldError>Das ist ein Pflichtfeld.</FieldError> }
              </InputSection>
              <InputSection>
                <Label>Nachname*</Label>
                <TextInput 
                  type="text" 
                  value={this.state.surname}
                  error={this.state.surnameError}
                  onChange={e => { this.setState({ surname: e.target.value }) }}  
                />
                { this.state.nameError && <FieldError>Das ist ein Pflichtfeld.</FieldError> }
              </InputSection>
              <InputSection>
                <Label>Telefonnummer*</Label>
                <div className="phone-fields">
                  <TextInput 
                    type="text" 
                    value={this.state.phone1}
                    error={this.state.phone1Error}
                    onChange={e => { this.setState({ phone1: e.target.value }) }}  
                  />
                  <TextInput 
                    type="text" 
                    value={this.state.phone2}
                    error={this.state.phone2Error}
                    onChange={e => { this.setState({ phone2: e.target.value }) }}  
                  />
                </div>
                { (this.state.phone1Error || this.state.phone2Error) && <FieldError>Geben Sie eine gültige Telefonnummer ein.</FieldError> }
              </InputSection>
              <InputSection>
                <Label>Email*</Label>
                <TextInput 
                  type="text" 
                  value={this.state.email}
                  error={this.state.emailError}
                  onChange={e => this.setState({ email: e.target.value })}  
                />
                { this.state.emailError && <FieldError>Geben Sie eine gültige Email ein.</FieldError> }
              </InputSection>
              <InputSection>
                <Label>Beschreiben Sie kurz Ihr Anliegen</Label>
                  <TextareaInput 
                    type="text" 
                    value={this.state.additionalInfo}
                    onChange={e => { this.setState({ additionalInfo: e.target.value }) }}  
                  />
              </InputSection>
              <InputSection>
                <LabeledCheckbox 
                  value={this.state.isPatient}
                  onChange={() => this.setState({ isPatient: !this.state.isPatient })}
                >
                  Sind Sie bereits Patient bei uns?
                </LabeledCheckbox>
              </InputSection>
              <InputSection>
                <LabeledCheckbox 
                  value={this.state.agreement1}
                  onChange={() => this.setState({ agreement1: !this.state.agreement1 })}
                 >
                   Wir verwenden Dienste für Audio- und Video-Konferenzen, um mit unseren Nutzern sowie sonstigen Personen kommunizieren zu können. Wir verwenden nur Dienste, bei denen ein angemessener Datenschutz gewährleistet ist. Es gelten ergänzend zu <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">unserer Datenschutzerklärung</a> jeweils auch allfällige Bedingungen der verwendeten Dienste wie beispielsweise Nutzungsbedingungen oder Datenschutzerklärungen.
                </LabeledCheckbox>
                 { this.state.agreement1Error && <FieldError>Ihre Zustimmung ist obligatorisch.</FieldError> }
              </InputSection>
              <RequiredInfo>* Benötigte Informationen</RequiredInfo>
              <ButtonsWrapper>
                <Link to={`/location-details/?place=${visitData.place && visitData.place.id}&category=${visitData.doctor && visitData.doctor.category.id}`}>&lt; Zurück</Link>
                <Button onClick={validateInputs}>Bestätigen</Button>
              </ButtonsWrapper>
            </LeftColumn>
            <RightColumn>
              <div className="first-line">
                <img src={URL + (visitData.doctor && visitData.doctor.pathImage)} alt={visitData.doctor && visitData.doctor.name} />
                <div>
                  <div className="name">{visitData.doctor && visitData.doctor.name}</div>
                  <div className="category">{visitData.doctor && visitData.doctor.category.name}</div>
                </div>
              </div>
              <div className="visit-info-line">
                <img src={calendarIcon} alt="visit date" />
                {visitData.start_date && moment(visitData.start_date).format('D MMMM YYYY, HH:mm')}
              </div>
              <div className="visit-info-line">
                <img src={pinIcon} alt="clinic location" />
                {visitData.place && `${visitData.place.city} ${visitData.place.street}`}
              </div>
              <div className="visit-info-line">
                <img src={phoneIcon} alt="clinic phone" />
                {visitData.place && visitData.place.phone}
              </div>
            </RightColumn>
          </Columns>
        </ColumnsWrapper>
      </div>
    )
  }
}

export default BookingPage