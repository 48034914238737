import React from 'react'
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  display: inline-block;
  position: relative;
  padding-left: 32px;
  margin-right: 32px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  a {
    color: #2f80ed;
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked ~ .checkmark {
    background-color: #2196F3;
    border: none;
  }

  &:checked ~ .checkmark:after {
    display: block;
  }
`

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 3px;
    left: 6px;
    width: 7px;
    height: 11px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const LabeledCheckbox = ({value, onChange, error, children}) => (
  <>
    {
      <CheckboxContainer onClick={e => {
          onChange(!value)
        }}>
        {children}
        <Input type="checkbox" checked={value} onChange={() => null} />
        <Checkmark className="checkmark" />
      </CheckboxContainer>
    }
    { error && <div className="input-error">{error}</div> }
  </>
)

export default LabeledCheckbox